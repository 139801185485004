import React, { useState, useEffect, useRef } from 'react';
import ReactMapBoxGl, { Marker, GeoJSONLayer } from 'react-mapbox-gl';

import './style.scss';
import { COUNTRIES } from './countryData';

const Map = ReactMapBoxGl({
  accessToken: process.env.GATSBY_MAPBOX_API_KEY,
  scrollZoom: false,
  minZoom: 2.5,
  maxZoom: 2.5,
});

const offSetValues = [[0, 0], [-55, 5], [5, -55], [0, 30], [55, 0][(55, 25)], [-25, -55]];

const getMarkers = (element, coordinates, offset = 0) => {
  const elementData = element?.data;
  return (
    <Marker coordinates={coordinates} offset={offSetValues[offset]} anchor="top-left">
      <div className="map-marker-individual">
        <img src={elementData?.actionPhoto?.url || ''} alt="person" width="90" height="90" />
      </div>
    </Marker>
  );
};
const MapComponent = ({ member = {}, showBy = '' }) => {
  const memberData = member?.data;
  const [placesToMap, setPlacesToMap] = useState([]);

  useEffect(() => {
    if (showBy === 'familyOrigin') {
      if (!!memberData?.familyOrigin) {
        setPlacesToMap(
          memberData?.familyOrigin?.map((origin) => {
            return COUNTRIES[origin.country[0].text.toLowerCase()];
          })
        );
      }
    } else if (showBy === 'birthCountry') {
      setPlacesToMap([COUNTRIES[memberData[showBy][0]?.text.toLowerCase()]]);
    }
  }, [showBy, member]);

  let mapRef = useRef();
  return (
    <>
      <link href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css" rel="stylesheet" />
      <Map
        style="mapbox://styles/lineagejourney/ckhz34cwa0xux1alun7bzqmq7"
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        center={placesToMap?.[0]?.coordinates || [0, 0]}
        movingMethod="easeTo"
        ref={mapRef}
      >
        {placesToMap.map((place) => getMarkers(member, place.coordinates, 0))}
        {placesToMap.map((places, index) => (
          <GeoJSONLayer
            data={places.tileset}
            layerId={`${places.coordinates[0]}`}
            id={`${places.coordinates[0]}-${index}`}
            key={`geoLayer-${places.coordinates[0]}`}
            fillPaint={{
              'fill-color': '#FFDB4D',
              'fill-opacity': 0.6,
            }}
          />
        ))}
      </Map>
    </>
  );
};

export default MapComponent;
