/**
 * For future use other countries GeoJson data can be found here
 * https://github.com/johan/world.geo.json
 */
export const COUNTRIES = {
  england: {
    coordinates: [-1.42154, 54.712928],
    tileset: require('./geoJson/GBR.geo.json'),
    flag: require('../../images/flags/britain.png'),
  },
  usa: {
    coordinates: [-104.015355, 38.026188],
    tileset: require('./geoJson/USA.geo.json'),
    flag: require('../../images/flags/usa.svg'),
  },
  'south africa': {
    coordinates: [23.513941, -29.731291],
    tileset: require('./geoJson/ZAF.geo.json'),
    flag: require('../../images/flags/sa.jpg'),
  },
  australia: {
    coordinates: [148.558156, -23.785133],
    tileset: require('./geoJson/AUS.geo.json'),
    flag: require('../../images/flags/aus.svg'),
  },
  phillipines: {
    coordinates: [121.487844, 13.859638],
    tileset: require('./geoJson/PHL.geo.json'),
    flag: require('../../images/flags/phillipines.webp'),
  },
  wales: {
    coordinates: [-1.42154, 54.712928],
    tileset: require('./geoJson/GBR.geo.json'),
    flag: require('../../images/flags/wales.png'),
  },
  'sri lanka': {
    coordinates: [80.783492, 7.137065],
    tileset: require('./geoJson/LKA.geo.json'),
    flag: require('../../images/flags/sri.webp'),
  },
  mauritius: {
    coordinates: [55.55463, -21.051334],
    tileset: require('./geoJson/MAU.geo.json'),
    flag: require('../../images/flags/mau.svg'),
  },
  iceland: {
    coordinates: [-19.286231, 64.298511],
    tileset: require('./geoJson/ISL.geo.json'),
    flag: require('../../images/flags/iceland.svg'),
  },
  jamaica: {
    coordinates: [-77.484979, 18.188986],
    tileset: require('./geoJson/JAM.geo.json'),
    flag: require('../../images/flags/jamica.svg'),
  },
  'jewish (poland)': {
    coordinates: [20.266834, 52.029756],
    tileset: require('./geoJson/POL.geo.json'),
    flag: require('../../images/flags/poland.png'),
  },
  germany: {
    coordinates: [10.440371, 50.774709],
    tileset: require('./geoJson/DEU.geo.json'),
    flag: require('../../images/flags/ger.png'),
  },
  japan: {
    coordinates: [139.470129, 36.735748],
    tileset: require('./geoJson/JPN.geo.json'),
    flag: require('../../images/flags/japan.png'),
  },
  trinidad: {
    coordinates: [-61.237824, 10.456282],
    tileset: require('./geoJson/TTO.geo.json'),
    flag: require('../../images/flags/taandt.svg'),
  },
  sinhalese: {
    coordinates: [80.783492, 7.137065],
    tileset: require('./geoJson/LKA.geo.json'),
    flag: require('../../images/flags/sri.webp'),
  },
  'indian tamil': {
    coordinates: [76.920156, 10.747789],
    tileset: require('./geoJson/IND.geo.json'),
    flag: require('../../images/flags/india.webp'),
  },
  tunisia: {
    coordinates: [9.559086, 34.651537],
    tileset: require('./geoJson/TUN.geo.json'),
    flag: require('../../images/flags/tunisia.svg'),
  },
  grenada: {
    coordinates: [-61.689185, 12.133591],
    tileset: require('./geoJson/Grenada.geo.json'),
    flag: require('../../images/flags/grenada.svg'),
  },
  'puerto rico': {
    coordinates: [-66.349344, 18.250002],
    tileset: require('./geoJson/PRI.geo.json'),
    flag: require('../../images/flags/puertorico.svg'),
  },
  mexico: {
    coordinates: [-102.317032, 24.445461],
    tileset: require('./geoJson/MEX.geo.json'),
    flag: require('../../images/flags/mexico.svg'),
  },
};
