import React, { useState } from 'react';
import { isEqual, isNaN } from 'lodash';
import ReactMapBoxGl, { Popup, Marker, GeoJSONLayer } from 'react-mapbox-gl';

import { COUNTRIES } from './countryData';
import IndividualMap from './individualMap';
import './style.scss';

const Map = ReactMapBoxGl({
  accessToken: process.env.GATSBY_MAPBOX_API_KEY,
  scrollZoom: false,
  minZoom: 1.3,
  maxZoom: 1.3,
});

const offSetValues = [
  [0, 0],
  [-60, 0],
  [60, 0],
  [0, 60],
  [0, -60],
  [-42, -42],
  [42, 42],
  [-42, 42],
  [42, -42],
];

const getMarkers = (element, onPress, handlePopUp, handleOnHovered, showBy = 'currentCountry', offset = 8) => {
  let offsetVal = isNaN(offset) ? 0 : offset + 1;
  const elementData = element?.data;
  const countryData = COUNTRIES[elementData[showBy][0].text.toLowerCase()];
  if (isEqual(elementData[showBy][0]?.text.toLowerCase(), 'wales')) offsetVal = 8;
  return (
    <Marker
      coordinates={countryData?.coordinates || [0, 0]}
      offset={offSetValues[offsetVal]}
      anchor="top-left"
      key={`${elementData?.name[0]?.text}-${offsetVal}`}
    >
      <div
        className="map-marker"
        onClick={onPress}
        onMouseEnter={() => {
          handlePopUp({
            coordinate: countryData?.coordinates || [0, 0],
            polygon: countryData?.tileset,
            member: element,
            offset: offSetValues[offsetVal],
          });
          handleOnHovered({ polygon: countryData?.tileset });
        }}
        onMouseLeave={() => {
          handleOnHovered(null);
          handlePopUp(null);
        }}
      >
        <img src={elementData?.portraitPhoto?.url || ''} alt="person" width="50" height="50" />
      </div>
    </Marker>
  );
};

const MapComponent = ({ mappingList = [], handleSelect }) => {
  const [popup, setPopup] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [showBy, setShowBy] = useState({
    key: 'currentCountry',
    title: 'CURRENTLY BASED',
  });
  let countryTally = {};

  return (
    <>
      <link href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css" rel="stylesheet" />
      <Map
        style="mapbox://styles/lineagejourney/ckhz34cwa0xux1alun7bzqmq7"
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        center={[20.743871, 30.329916]}
        onClick={() => {
          setPopup(null);
        }}
      >
        <div className="map-overlay">
          <div className="switch">
            <span className={`current ${isEqual(showBy.key, 'currentCountry') ? 'active' : ''}`}>CURRENTLY BASED</span>
            <label className="btn-switch">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (isEqual(showBy.key, 'currentCountry')) {
                    setShowBy({ key: 'birthCountry', title: '' });
                  } else {
                    setShowBy({
                      key: 'currentCountry',
                      title: 'CURRENTLY BASED',
                    });
                  }
                }}
              />
              <span className="switch-slider"></span>
            </label>
            <span className={`family ${isEqual(showBy.key, 'birthCountry') ? 'active' : ''}`}>BIRTH COUNTRY</span>
          </div>
        </div>
        {mappingList?.map((member, index) => {
          const memberData = member.data;
          let countryCount = countryTally[memberData[showBy.key][0]?.text];
          let updatedCount = !isNaN(countryCount) ? countryCount + 1 : 0;
          countryTally = {
            ...countryTally,
            [memberData[showBy.key][0]?.text]: updatedCount,
          };
          return getMarkers(
            member,
            () => {
              handleSelect(index);
            },
            setPopup,
            setHovered,
            showBy.key,
            updatedCount
          );
        })}
        {popup && (
          <Popup offset={popup.offset} key={popup.member.data.name[0].text} coordinates={popup.coordinate}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: 120,
              }}
            >
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    height: 35,
                    width: 35,
                    borderRadius: 60,
                    borderWidth: 1,
                    borderColor: 'white',
                    borderStyle: 'solid',
                  }}
                >
                  <img src={popup?.member?.data?.actionPhoto?.url} alt="" height="40" width="40" />
                </div>
              </div>
              <div style={{ flex: 2, padding: 5 }}>
                <p
                  style={{
                    fontSize: 9,
                    fontFamily: 'Lato',
                    color: '#0A0A0A',
                    textAlign: 'center',
                    fontWeight: '700',
                    lineHeight: '12px',
                  }}
                >
                  {popup?.member?.data?.name[0]?.text.toUpperCase()}
                </p>
              </div>
            </div>
          </Popup>
        )}
        {hovered?.polygon && (
          <GeoJSONLayer
            data={hovered.polygon.features[0]}
            fillPaint={{
              'fill-color': '#FFDB4D',
              'fill-opacity': 0.6,
            }}
          />
        )}
      </Map>
    </>
  );
};

export { IndividualMap };
export default MapComponent;
